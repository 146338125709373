

// let permission=[
//   "Facturation Fournisseurs",
//   "Facturation Clients",
//   "Dashbaord",
//   "Paramètrage"
// ];
// if(JSON.parse(localStorage.getItem('access'))){
  
// }
let permission=JSON.parse(localStorage.getItem('access'));
let dashboard=[];
// if (localStorage.getItem("access") != null){
  if(permission.includes('Dashbaord') || permission.includes('Admin')){
    let side=   {
      title: 'Dashboards',
      icon: 'HomeIcon',
      route: 'dashboard-ecommerce',
      perm:'Dashbaord'
        };
      dashboard.push(side);
}

if(permission.includes('Utilisateurs') || permission.includes('Admin')){
    let side=  {
      title: 'Utilisateurs',
      route: 'users-list',
      icon: 'UsersIcon',
      perm:'Utilisateurs'

    };
  dashboard.push(side);
}
if(permission.includes('Facturation Fournisseurs') || permission.includes('Admin')){
let side=   {
  title: 'Fournisseurs',
  // route: 'fournisseurs-list',
  icon: 'TruckIcon',
  perm:'Facturation Fournisseurs',

  children: [
    {
      title: 'Liste des fournisseurs',
      route: 'fournisseurs-list',
    },
    // { 
    //   // FactureFournisseur-list.vue
    //   title: 'Factures Fournisseurs',
    //   route: 'facture-fournis-list',
    // },
    { 
      // FactureFournisseur-list.vue
      title: 'Factures Fournisseurs',
      route: 'facture-list',
    },
    {
      title: `Bon d'avoir`,
      route: 'BonAvoir-list',
      // icon: 'ListIcon',
    },
    {
      title: `Bons de commande`,
      route: 'commandes_list',
      // icon: 'ListIcon',
    },
   
    
  ]
};
  dashboard.push(side);
}
if(permission.includes('Produits & Stock') || permission.includes('Admin')){
let side=   {
  title: 'Produits',
  route: 'produits-list',
  icon: 'ShoppingBagIcon',
  perm:'Produits & Stock',

};
  dashboard.push(side);
}
if(permission.includes('Catégories') || permission.includes('Admin')){
let side=  {
  title: 'Catégories',
  route: 'categories-list',
  icon: 'ListIcon',
  perm:'Catégories',

  };
  dashboard.push(side);
}
if(permission.includes('Facturation Clients') || permission.includes('Admin')){
let side=    {
  title: 'Clients',
  // route: 'clients-list',
  icon: 'UsersIcon',
  perm:'Facturation Clients',

  children: [
    {
      title: 'Liste des clients',
      route: 'clients-list',
    },
    { 
      title: 'Devis',
      route: 'devis-client-list',
    },
    { 
      title: 'Bon de livraison',
      route: 'bl-client-list',
    },
    { 
      title: 'Factures Client',
      route: 'fact-client-list',
    },
    
  ]
};
  dashboard.push(side);
}

if(permission.includes('Paramètrage') || permission.includes('Admin')){
let side=   {
  title: 'Paramètrage',
  route: 'settings-page',
  icon: 'SettingsIcon',
  perm:'Paramètrage',

    };
  dashboard.push(side);
}
// export default dashboard;
// }
  
  
        export default dashboard;
/* export default [
 
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-ecommerce',
   
  },
  {
    title: 'Utilisateurs',
    route: 'users-list',
    icon: 'UsersIcon',
  },
  //  {
  //   title: 'FournisseursFfact',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Preview',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Add',
  //       route: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },
  {
    title: 'Fournisseurs',
    // route: 'fournisseurs-list',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Liste des fournisseurs',
        route: 'fournisseurs-list',
      },
      // { 
      //   // FactureFournisseur-list.vue
      //   title: 'Factures Fournisseurs',
      //   route: 'facture-fournis-list',
      // },
      { 
        // FactureFournisseur-list.vue
        title: 'Factures Fournisseurs',
        route: 'facture-list',
      },
      {
        title: `Bon d'avoir`,
        route: 'BonAvoir-list',
        icon: 'ListIcon',
      },
     
      
    ]
  },
  // {
  //   title: `Bon d'avoir`,
  //   route: 'BonAvoir-list',
  //   icon: 'ListIcon',
  // },
  {
    title: 'Produits',
    route: 'produits-list',
    icon: 'ShoppingBagIcon',
  },
   {
    title: 'Catégories',
    route: 'categories-list',
    icon: 'ListIcon',
  },
  {
    title: 'Clients',
    // route: 'clients-list',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Liste des clients',
        route: 'clients-list',
      },
      { 
        // FactureFournisseur-list.vue
        title: 'Devis',
        route: 'devis-client-list',
      },
      { 
        // FactureFournisseur-list.vue
        title: 'Bon de livraison',
        route: 'bl-client-list',
      },
      { 
        // FactureFournisseur-list.vue
        title: 'Factures Client',
        route: 'fact-client-list',
      },
      
    ]
  },
  {
    title: 'Paramètrage',
    route: 'settings-page',
    icon: 'SettingsIcon',
  },
  // {
  //   title: 'Factures Fournisseurs',
  //   route: 'ui-feather',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'Factures Produits',
  //   route: 'ui-feather',
  //   icon: 'FileTextIcon',
  // },
] */
